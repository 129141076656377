<template>
  <div class="FinancialManagement">
    <div
      class="FinancialManagement_left"
      :class="{ FinancialManagement_left1: show == false }"
    >
      <el-menu class="el_menu" :default-active="$route.path" router v-show="show" :default-openeds="['/Finance','/Achievements']">
        <div v-for="(item, index) in nav_menu_data" :key="index">
          <div v-show="item.menulist&&item.id=='1'">
            <el-submenu :index="item.path">
              <span slot="title">{{item.title}}</span>
              <div v-for="(iten ,indexs) in item.menulist" :key="indexs">
                <el-menu-item :index="iten.path">
                  <span>{{iten.title}}</span>
                </el-menu-item>
              </div>
            </el-submenu>
          </div>
          <div v-show="!item.menulist&&item.id=='1'">
            <el-menu-item
              :index="item.path"
            >
              <span slot="title">
                {{ item.title }}
              </span>
            </el-menu-item>
          </div>
        </div>
      </el-menu>
    </div>
    <div class="FinancialManagement_center">
      <img src="../../assets/image/guan.png" @click="close" v-show="show"/>
      <img src="../../assets/image/kai.png" @click="open" v-show="!show"/>
    </div>
    <div
      class="FinancialManagement_right"
      :class="{ FinancialManagement_right1: show == false }"
    >
      <div  v-show="$route.path == '/ArchivesFinance/FinanceZ'">
        <guidance-chart :tips="tips" @tips-child="tipsChild"></guidance-chart>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
// import api from "../../api/Grantsandmilestones"
// import {mapState} from "vuex";
export default {
  data(){
    return{
      tips: {
        name: "archivesManagement",
        url: require('../../assets/image/tips/archives.png')
      },
      show: true,
      nav_menu_data: [
        {
          id:"1",
          title: "财务档案",
          path: "/Finance",
          menulist: [
            {
              title:'相关文件',
              path: "/ArchivesFinance/FinanceZ"
            },
            {
              title:'财务凭证',
              path: "/ArchivesFinance/FinanceVoucher"
            },

          ]
        },
        { id:"1",
          title: "任务档案",
          path: "/AaskAechive"
        },
        {
          id:"1",
          title: "成果档案",
          path: "/Achievements",
          menulist: [
            {
              title: "论文",
              path: "/Achievements/Paper",
            },
            {
              title: "专利",
              path: "/Achievements/Patent",
            },
            {
              title: "指南/共识",
              path: "/Achievements/GuideExpert",
            },
            {
              title: "总结/报告",
              path: "/Achievements/SummaryReport",
            },
            {
              title: "原始数据",
              path: "/Achievements/RawData",
            },
            {
              title: "其他",
              path: "/Achievements/OtherFile",
            }
          ]
        },
      ],
    }
  },
  // computed: mapState(["midlist"]),
  created(){
    // alert(this.midlist)
    // console.log(this.midlist);
    this.calculation()
  },
  mounted(){
    //  this.calculation()
  },
  methods:{
    calculation(){
           var arr=window.sessionStorage.getItem("midshow").split(",")
           if(arr[0]==0){
             this.nav_menu_data[2].id=0
           }
           if(arr[1]==0){
             this.nav_menu_data[1].id=0
           }
           if(arr[2]==0){
             this.nav_menu_data[0].id=0
           }
           console.log(this.nav_menu_data);
    },
    tipsChild(data) {
      localStorage.setItem(this.tips.name,data)
    },
    close() {//关
      this.show = false;
    },
    open(){//关
      this.show = true;
    }
  },
}
</script>
<style lang="scss" scoped>
.FinancialManagement {
  width: 1920px;
  height: 100%;
  .FinancialManagement_left {
    width: 140px;
    height: 100%;
    float: left;
    transition: width 1s;
    background-color: white;
    box-shadow: 3px 0 6px rgba(0, 104, 163, 0.1);
    .el_menu{
      width: 140px;
      span {
        font-size: 18px;
      }
    }
  }
  .FinancialManagement_left1 {
    width: 0px;
  }
  .FinancialManagement_center {
    width: 30px;
    height: 100%;
    //background: chartreuse;
    float: left;
    position: relative;
    img {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 45%;
    }
  }
  .FinancialManagement_right {
    width: 1750px;
    height: 100%;
    float: left;
    transition: width 1s;
  }
  .FinancialManagement_right1 {
    width: 1890px;
  }
}
/deep/ .el-menu-item.is-active {
  border-left: 4px solid #409eff;
  color: #303133;
  padding-left: 16px!important;
}
/deep/  .el-submenu .el-menu-item.is-active {
  border-left: 4px solid #409eff;
  color: #303133;
  padding-left: 36px!important;
}

</style>
